<template>
  <div
    class="popup-actions"
    :class="{
      'popup-actions--disabled': disabled
    }"
  >
    <div
      ref="elementTriggerRef"
      data-popup-action="popupAction"
      @mouseenter="onOpen"
      @click.prevent="onOpen"
    >
      <div
        class="popup-actions-icon__wrapper"
        :class="{
          active: !isHoverMode && isOpen
        }"
      >
        <slot>
          <SvgIcon :src="EIconPath.ActionMoreDotsSvg" />
        </slot>
      </div>
    </div>
    <Teleport to="body">
      <ul
        v-if="isOpen"
        ref="popupContentRef"
        class="popup-actions__content"
        :class="popupClass"
        :style="{
          zIndex: popupContentZIndex,
        }"
      >
        <li
          v-for="action in actions"
          :key="action.id"
          data-popup-action="popupAction"
          class="popup-actions__content__item"
          :class="{
            disabled: action.disabled,
            selected: selectedAction?.id === action.id
          }"
          :style="`white-space: ${props.wrapped ? 'pre-line' : ''}`"

          @click="onClickAction(action)"
        >
          {{ action.name }}
          <SvgIcon
            v-if="selectable && selectedAction?.id === action.id"
            :src="EIconPath.IndicatorsCheckmarkSimpleSvg"
          />
        </li>
      </ul>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IAction } from '../models/action.model';
import { EIconPath } from '../enums/iconPath.enum';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';
import { WatchSubscription } from '../utils/watchSubscription';
import { useClickOutside } from '../composables/useClickOutside';


const props = withDefaults(defineProps<{
  /**
   * Массив действий для отображения
   */
  actions: Array<IAction>;
  /**
   * Отключить весь компонент
   */
  disabled?: boolean;
  /**
   * Функция кастомного расчёта горизонтальной позиции
   */
  calculateHorizontalPosition?: (element: HTMLDivElement, scrollX: number, left: number, popupElement: HTMLDivElement) => `${number}px`;
  /**
   * Показывать справа
   */
  isRightPosition?: boolean;
  /**
   * Класс обертки
   */
  popupClass?: string;
  /**
   * Для расчета позиционирования
   */
  popupGap?: number;
  /**
   * Для изменения white-spacing
   */
  wrapped?: boolean;
  /**
   * Для отображения галочки рядом с выбранным элементом
   */
  selectable?: boolean;
  /**
   * Регулирует как открывается попап
   *
   * `hover` - при наведении на иконку,
   * `click` - при клике на иконку
   *
   * @default "hover"
   */
  openMode?: 'hover' | 'click'
  /**
   * Z index выпадающего списка
   */
  popupContentZIndex: number,
}>(), {
  styles: {},
  popupGap: 4,
  wrapped: false,
  openMode: 'hover',
});

const emit = defineEmits<{
  (e: 'select', value: IAction): void;
}>();

const popupContentRef = ref<HTMLDivElement>();
const elementTriggerRef = ref<HTMLDivElement>();
const isOpen = ref(false);
const timeoutToClose = ref(null);
const selectedAction = ref<IAction<string | number> | null>(null);
const isHoverMode = computed<boolean>(() => props.openMode === 'hover');
const watchSubscription = new WatchSubscription();

useClickOutside(elementTriggerRef, () => onClose(), undefined, (ref, target) => {
  if (!ref || !target) {
    return false;
  };
  return ref?.dataset?.popupAction === target?.dataset?.popupAction;
});

function onOpen(event: MouseEvent): void {
  if (props.disabled) {
    return;
  }

  const hoverEvent = event.type === 'mouseenter';
  const clickEvent = event.type === 'click';

  if ((hoverEvent && !isHoverMode.value) || (clickEvent && isHoverMode.value)) {
    return;
  }

  const calcPopupPosition = () => nextTick(() => {
      calculatePositionDropdown();
    });

  if (hoverEvent) {
    if (timeoutToClose.value) {
      clearTimeout(timeoutToClose.value);
      timeoutToClose.value = null;
    }
    isOpen.value = true;
    calcPopupPosition();
  }

  if (clickEvent) {
    if (isOpen.value) {
      isOpen.value = false;
    } else {
      isOpen.value = true;
      calcPopupPosition();
    }
  }
}

function calculatePositionDropdown(): void {
  if (!elementTriggerRef.value || !popupContentRef.value) {
    return;
  }
  const {
    height: triggerHeight,
    top: triggerTop,
    left: triggerLeft,
    right: triggerRight,
    width: triggerWidth,
  } = elementTriggerRef.value.getBoundingClientRect();
  const {
    height: popupHeight,
    width: popupWidth,
  } = popupContentRef.value.getBoundingClientRect();

  const scrollX = window.scrollX || window.pageXOffset;
  const scrollY = window.scrollY || window.pageYOffset;

  const popupFinalLowerBottomPosition = scrollY + triggerTop + triggerHeight + popupHeight;

  if (window.innerHeight + window.pageYOffset < popupFinalLowerBottomPosition) {
    popupContentRef.value.style.top = popupFinalLowerBottomPosition - props.popupGap - popupHeight * 2 - triggerHeight + 'px';
  }
  else {
    popupContentRef.value.style.top = scrollY + triggerTop + triggerHeight + 'px';
  }

  if (props.calculateHorizontalPosition) {
    popupContentRef.value.style.left = props.calculateHorizontalPosition(elementTriggerRef.value, scrollX, triggerLeft, popupContentRef.value);
  } else {
    // Случай когда элемент, открывающий попап, находится в правой стороне экрана
    if (triggerLeft + popupWidth >= triggerRight) {
      popupContentRef.value.style.left = `${triggerLeft - popupWidth + triggerWidth}px`;
    } else {
      popupContentRef.value.style.left = `${scrollX + triggerLeft}px`;
    }
  }
}

async function onClose(mouseLeave = false): Promise<void> {
  if (mouseLeave) {
    await new Promise((resolve) => timeoutToClose.value = setTimeout(() => resolve(1), 50));
  }
  isOpen.value = false;
}

function onClickAction(action: IAction): void {
  if (action.disabled) {
    return;
  };

  selectedAction.value = action;
  emit('select', action);

  if (!isHoverMode.value) {
    onClose();
  }
}

function mouseMoveHandler(event: MouseEvent): void {
  const path = event.path || event.composedPath?.();
  if (path && !path.some((item) => item === elementTriggerRef.value || item === popupContentRef.value) && isOpen.value) {
    onClose();
  }
}

function initMouseListener(): void {
  if (process.client) {
    document.addEventListener('mousemove', mouseMoveHandler);
  }
}

function removeListener(): void {
  if (process.client) {
    document.removeEventListener('mousemove', mouseMoveHandler);
  }
}

watchSubscription.add(
  watch(
    () => isHoverMode.value,
    (newValue) => {
      if (newValue) {
        initMouseListener();
      }
    },
    {
      immediate: true,
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onBeforeUnmount(() => {
  onClose();
  removeListener();
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.popup-actions {
  position: relative;
  z-index: 1000;

  &__content {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style-type: none;
      padding-bottom: 20px;
      color: $text-dark-green;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      user-select: none;
      justify-content: space-between;
      white-space: nowrap;

      &.disabled {
        color: $text-disabled;
      }

      &.selected {
        gap: 16px;
      }

      & :deep(svg) {
        path {
          stroke: $light-green-2;
        }
      }
    }
  }

  &-icon {
    &__wrapper {
      width: 24px;
      height: 24px;
      background-color: $light-gray;
      border-radius: 6px;
      cursor: pointer;

      &.active {
        :deep(path) {
          fill: $link;
        }
      }

      :deep(svg) {
        pointer-events: none;
      }

      &:hover {
        :deep(svg) {
          circle {
            fill: $link;
          }
        }
      }
    }
  }

  &--disabled &-icon__wrapper {
    cursor: not-allowed !important;

    :deep(svg) {
      circle {
        fill: $text-disabled !important;
      }
    }
  }
}

:global(.popup-actions__content) {
  width: fit-content;
  background-color: $white;
  padding: 20px 16px 0;
  box-shadow: -2px 2px 16px rgb(3 25 18 / 12%);
  border-radius: 6px;
  position: absolute;
  margin: 0;
  z-index: 9999;
  margin-top: 4px;
}

:global(.popup-actions__content__item) {
  list-style-type: none;
  padding-bottom: 20px;
  color: $text-dark-green;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

:global(.popup-actions__content__item.disabled) {
  color: $text-disabled;
  cursor: not-allowed;
}

:global(.popup-actions__content::before) {
  content: '';
  width: 100%;
  position: absolute;
  height: 15px;
  top: -15px;
  left: 0;
  right: 0;
}
</style>
